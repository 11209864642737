import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import notFoundImg from "../../imgs/404 Error-amico (1).svg";

export default function NotFound() {
  return (
    <>
      <Helmet>
        <title>INNOVA | Not Found</title>
      </Helmet>
      <section id="NotFound" className="mt-5">
        <div className="container mt-3">
          <div className={`text-center div`}>
            <img className="error" src={notFoundImg} alt="Not Found" />
            <h2>
              OOPS Page Not Found{" "}
              <Link className="a" to="/">
                Go To Home
              </Link>
            </h2>
          </div>
        </div>
      </section>
    </>
  );
}
