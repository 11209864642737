import React, { useContext, useEffect } from "react";
import MainSecHome from "../MainSecHome/MainSecHome";
import AboutSecHome from "../AboutSecHome/AboutSecHome";
import NewsSecHome from "../NewsSecHome/NewsSecHome";
import ServicesSecHome from "../ServicesSecHome/ServicesSecHome";
import ProjectsSecHome from "../ProjectsSecHome/ProjectsSecHome";
import PartnersSecHome from "../PartnersSecHome/PartnersSecHome";
import CertificatesSecHome from "../CertificatesSecHome/CertificatesSecHome";
import ContactSecHome from "../ContactSecHome/ContactSecHome";
import { LoaderContext } from "../../context/LoaderContext";
import Loader from "../Loader/Loader";
import { Helmet } from "react-helmet";

export default function Home() {
  const LoaderContextVarible = useContext(LoaderContext);

  useEffect(() => {
    if (
      LoaderContextVarible.loaderMain ||
      LoaderContextVarible.loaderSlides ||
      LoaderContextVarible.loaderNews ||
      LoaderContextVarible.loaderServices ||
      LoaderContextVarible.loaderCertificates ||
      LoaderContextVarible.loaderCareer
    ) {
      document.body.style = "max-height : 100vh; overflow-y: hidden";
    } else {
      document.body.style = "max-height : auto; overflow-y: auto";
    }
  }, [
    LoaderContextVarible.loaderMain,
    LoaderContextVarible.loaderSlides,
    LoaderContextVarible.loaderNews,
    LoaderContextVarible.loaderServices,
    LoaderContextVarible.loaderCertificates,
    LoaderContextVarible.loaderCareer,
  ]);
  return (
    <>
      <Helmet>
        <title>INNOVA | Home</title>
      </Helmet>
      {(LoaderContextVarible.loaderMain ||
        LoaderContextVarible.loaderSlides ||
        LoaderContextVarible.loaderNews ||
        LoaderContextVarible.loaderServices ||
        LoaderContextVarible.loaderCertificates ||
        LoaderContextVarible.loaderCareer) && <Loader />}
      <MainSecHome />
      <AboutSecHome />
      <NewsSecHome />
      <ServicesSecHome />
      <ProjectsSecHome />
      <PartnersSecHome />
      <CertificatesSecHome />
      <ContactSecHome />
    </>
  );
}
