import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import { FreeMode, Autoplay } from "swiper/modules";

import { useTranslation } from "react-i18next";
import { LoaderContext } from "../../context/LoaderContext";

export default function NewsSecHome() {
  const LoaderContextVarible = useContext(LoaderContext);

  const [t, i18n] = useTranslation("global");

  const [data, setData] = useState([]);
  useEffect(() => {
    LoaderContextVarible.setLoaderNews(true);

    fetch("https://admin.innova.com.eg/api/blogs", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-api-token":
          "d5eab596caa0128af68cd5d2e56219c2e3945fb11c5f3fb3cc4245576bbd5c387aab3f1f3fd9a89ecd96cc35d6a9574e31b0c669201df343d299eae97b64f618",
          "Accept-Language": i18n.language,
        },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data.data);
        setTimeout(() => {
          LoaderContextVarible.setLoaderNews(false);
        }, 1000);
      })
      .catch((error) => {
        setTimeout(() => {
          LoaderContextVarible.setLoaderNews(false);
        }, 1000);
      });
  }, [i18n.language]);

  return (
    <>
      <section id="NewsSecHome">
        <div className="container">
          <div className="position-relative main-banner">
            <h2 className="main-banner-h2">{t("NewsSecHome.news.title")}</h2>
            <p className="main-banner-p">{t("NewsSecHome.news.subtitle")}</p>
          </div>
          <Swiper
            dir="ltr"
            className="mySwiper"
            spaceBetween={5}
            grabCursor={true}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            freeMode={true}
            modules={[FreeMode, Autoplay]}
            loop={true}
            slidesPerView={"auto"}
            breakpoints={{
              280: {
                slidesPerView: 1,
                spaceBetween: 5,
              },
              700: {
                slidesPerView: 2,
                spaceBetween: 5,
              },
              1024: {
                slidesPerView: 2,
                spaceBetween: 5,
              },
            }}
          >
            {data.map((el, i) => (
              <SwiperSlide key={i}>
                <div className="newsDiv">
                  <Link to={`/NewsDetails/${el.id}`}>
                    <div className="text">
                      <p className="h3 m-3">{el.title}</p>
                    </div>
                    <img
                      className="w-100"
                      alt={el.image_title}
                      src={el.image}
                    />
                  </Link>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
    </>
  );
}
