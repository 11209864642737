import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LoaderContext } from "../../context/LoaderContext";

export default function CertificatesSecHome() {
  const LoaderContextVarible = useContext(LoaderContext);
  const { t } = useTranslation("global");

  const [data, setData] = useState([]);

  useEffect(() => {
    fetch("https://admin.innova.com.eg/api/certificates", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-api-token":
          "d5eab596caa0128af68cd5d2e56219c2e3945fb11c5f3fb3cc4245576bbd5c387aab3f1f3fd9a89ecd96cc35d6a9574e31b0c669201df343d299eae97b64f618",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((res) => {
        setData(res.data);
        LoaderContextVarible.setLoaderCertificates(false);
      })
      .catch((error) => {
        LoaderContextVarible.setLoaderCertificates(false);
      });
  }, []);

  return (
    <section id="CertificatesSecHome">
      <div className="container">
        <div className="position-relative main-banner">
          <h2 className="main-banner-h2">
            {t("CertificatesSecHome.certificates.title")}
          </h2>
          <p className="main-banner-p">
            {t("CertificatesSecHome.certificates.subtitle")}
          </p>
        </div>
        <div className="containerX row g-3">
          {data?.map((el, i) => (
            <img
              key={i}
              className="col-lg-4 col-md-6 certificatesImg"
              src={el?.certificate}
              alt=".."
            />
          ))}
        </div>
      </div>
    </section>
  );
}
