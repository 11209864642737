import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Loader from "../Loader/Loader";

import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { FreeMode, Autoplay } from "swiper/modules";
import { LoaderContext } from "../../context/LoaderContext";

export default function NewsDetails() {
  const LoaderContextVarible = useContext(LoaderContext);
  const [t, i18n] = useTranslation("global");
  const params = useParams();

  const [newsDetails, setNewsDetails] = useState([]);
  useEffect(() => {
    LoaderContextVarible.setLoaderNewsDetails(true);

    fetch(`https://admin.innova.com.eg/api/blog/view/${params.id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-api-token":
          "d5eab596caa0128af68cd5d2e56219c2e3945fb11c5f3fb3cc4245576bbd5c387aab3f1f3fd9a89ecd96cc35d6a9574e31b0c669201df343d299eae97b64f618",
        "Accept-Language": i18n.language,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setNewsDetails(data.data);
        setTimeout(() => {
          LoaderContextVarible.setLoaderNewsDetails(false);
        }, 1000);
      })
      .catch((error) => {
        setTimeout(() => {
          LoaderContextVarible.setLoaderNewsDetails(false);
        }, 1000);
      });
  }, [params.id, i18n.language]);

  const [allData, setAllData] = useState([]);
  useEffect(() => {
    fetch("https://admin.innova.com.eg/api/blogs", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-api-token":
          "d5eab596caa0128af68cd5d2e56219c2e3945fb11c5f3fb3cc4245576bbd5c387aab3f1f3fd9a89ecd96cc35d6a9574e31b0c669201df343d299eae97b64f618",
        "Accept-Language": i18n.language,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setAllData(data.data);
      });
  }, [params.id, i18n.language]);

  return (
    <>
      {LoaderContextVarible.loaderNewsDetails ? (
        <Loader />
      ) : (
        <section id="NewsDetails" className="mt-5">
          <div className="container mt-3">
            <div className="position-relative main-banner">
              <h2 className="main-banner-h2">{t("NewsSecHome.news.title")}</h2>
              <p className="main-banner-p">{t("NewsSecHome.news.subtitle")}</p>
            </div>
            <div className="all-news">
              <img
                className="img-fluid w-100"
                alt={newsDetails.image_title}
                src={newsDetails.image}
              />
              <h2 className="main-color my-3">{newsDetails.title}</h2>
              <div className="mb-2 d-flex justify-content-between wrap">
                <p>
                  {t("gen.auther")} : {newsDetails.auther}
                </p>
                <p>
                  {t("gen.date")} : {newsDetails.date}
                </p>
              </div>
              {React.createElement("div", {
                className: "text-justify mb-2",
                dangerouslySetInnerHTML: { __html: newsDetails.description },
              })}
            </div>
            <Swiper
              dir="ltr"
              className="mySwiper mt-3"
              spaceBetween={5}
              slidesPerView={3}
              grabCursor={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              freeMode={true}
              modules={[FreeMode, Autoplay]}
              loop={true}
            >
              {allData.map((el, i) => (
                <SwiperSlide key={i}>
                  <div className="newsDiv">
                    <Link to={`/NewsDetails/${el.id}`}>
                      <div className="text">
                        <p className="h3">{el.title}</p>
                      </div>
                      <img
                        className="w-100"
                        alt={el.image_title}
                        src={el.image}
                      />
                    </Link>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </section>
      )}
    </>
  );
}
