import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Gallery from "./components/Gallery/Gallery";
import Projects from "./components/Projects/Projects";
import ProjectsDetails from "./components/ProjectsDetails/ProjectsDetails";
import NewsDetails from "./components/NewsDetails/NewsDetails";
import Careers from "./components/Careers/Careers";
import CareersDetails from "./components/CareersDetails/CareersDetails";
import NotFound from "./components/NotFound/NotFound";

import { LoaderContextProvider } from "./context/LoaderContext";
// transition
import global_en from "./translation/en/global.json";
import global_ar from "./translation/ar/global.json";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
i18next.init({
  interpolation: { escapeValue: true },
  lng: "en",
  resources: {
    en: {
      global: global_en,
    },
    ar: {
      global: global_ar,
    },
  },
});

export default function App() {
  let routes = createBrowserRouter([
    {
      path: "",
      element: <Layout />,
      children: [
        { index: true, element: <Home /> },
        { path: "/about", element: <About /> },
        { path: "/projects", element: <Projects /> },
        { path: "/gallery", element: <Gallery /> },
        { path: "/projectsDetails/:id", element: <ProjectsDetails /> },
        { path: "/newsDetails/:id", element: <NewsDetails /> },
        { path: "/careers", element: <Careers /> },
        { path: "/careersDetails/:id", element: <CareersDetails /> },

        { path: "*", element: <NotFound /> },
      ],
    },
  ]);
  return (
    <LoaderContextProvider>
      <I18nextProvider i18n={i18next}>
        <RouterProvider router={routes}></RouterProvider>
      </I18nextProvider>
    </LoaderContextProvider>
  );
}
