import React, { useContext, useEffect } from "react";
import logoImg from "../../imgs/logoLight.png";
import MainBtn from "../MainBtn/MainBtn";
// import { Link } from "react-router-dom";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LoaderContext } from "../../context/LoaderContext";
function Footer() {
  const LoaderContextVarible = useContext(LoaderContext);
  const { t } = useTranslation("global");
  const year = new Date().getFullYear();
  const pathname = window.location.pathname;
  useEffect(() => {
    // تحديد السيكشن المحدد بناءً على الهاش في رابط الصفحة
    const hash = window.location.hash;
    if (hash) {
      const sectionId = hash.slice(1); // إزالة علامة الهاش #
      const sectionElement = document.getElementById(sectionId);
      if (sectionElement) {
        sectionElement.scrollIntoView();
      }
    }
  }, []);
  const handleLinkClick = (sectionId) => {
    if (pathname === "/") {
      setTimeout(() => {
        const sectionElement = document.getElementById(sectionId);
        sectionElement.scrollIntoView(sectionId);
      }, 1);
    } else {
      setTimeout(() => {
        const sectionElement = document.getElementById(sectionId);
        sectionElement.scrollIntoView(sectionId);
      }, 1001);
    }
  };

  return (
    <footer id="Footer">
      <div className="container text-center text-lg-start py-5">
        <div className="row">
          <div className="col-lg-4 div">
            <img src={logoImg} alt="Innova" />
            <p className="text-white-50 mt-4 mb-2">
              {t("footer.innovaDescription")}
            </p>
            <MainBtn
              text={
                <a
                  className="MainBtn"
                  href={`mailto:${LoaderContextVarible?.emailFooter}`}
                >
                  {t("footer.btn")}
                </a>
              }
            />
            <p className="text-white-50 mt-2 mb-0">
              © {year} - <span>{t("footer.innova")}</span>
            </p>
          </div>

          <div className="col-lg-2 div text-white-50 ">
            <h2 className="fw-bold my-2 h5">{t("footer.usefulLinks")}</h2>
            <div className="Links-Div">
              <Link
                className="link"
                to="/#AboutSecHome"
                onClick={() => handleLinkClick("AboutSecHome")}
              >
                {t("footer.brief")}
              </Link>
              <Link
                className="link"
                to="/#NewsSecHome"
                onClick={() => handleLinkClick("NewsSecHome")}
              >
                {t("footer.latestNews")}
              </Link>
              <Link
                className="link"
                to="/#ServicesSecHome"
                onClick={() => handleLinkClick("ServicesSecHome")}
              >
                {t("footer.bestServices")}
              </Link>
              <Link
                className="link"
                to="/#ProjectsSecHome"
                onClick={() => handleLinkClick("ProjectsSecHome")}
              >
                {t("footer.latestProjects")}
              </Link>
              <Link
                className="link"
                to="/#PartnersSecHome"
                onClick={() => handleLinkClick("PartnersSecHome")}
              >
                {t("footer.partners")}
              </Link>
              <Link
                className="link"
                to="/#CertificatesSecHome"
                onClick={() => handleLinkClick("CertificatesSecHome")}
              >
                {t("footer.certificates")}
              </Link>
              <Link
                className="link"
                to="/#ContactSecHome"
                onClick={() => handleLinkClick("ContactSecHome")}
              >
                {t("footer.contactUs")}
              </Link>
            </div>
          </div>

          <div className="col-lg-2 div text-white-50">
            <h2 className="fw-bold my-2 h5">{t("footer.pages")}</h2>
            <div className="Links-Div">
              <NavLink
                className="link"
                to="/"
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                {t("footer.home")}
              </NavLink>
              <NavLink
                className="link"
                to="/about"
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                {t("footer.aboutUs")}
              </NavLink>
              <NavLink
                className="link"
                to="/projects"
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                {t("footer.projects")}
              </NavLink>
              <NavLink
                className="link"
                to="/careers"
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                {t("navbar.careers")}
              </NavLink>
            </div>
          </div>

          <div className="col-lg-4 div text-white-50">
            <h2 className="fw-bold my-2 h5">{t("footer.contactUsTitle")}</h2>
            <div className="mb-2 all-div">
              <div className="d-flex align-items-centerX">
                <i className="fa-solid fa-location-dot fa-3 px-2 py-1"></i>
                <p className="mb-0">{t("footer.EG")}</p>
              </div>
              <div className="d-flex align-items-centerX wrap">
                <i className="fa-solid fa-phone fa-3 px-2 py-1"></i>
                <a className="link" href="tel:+201065532784">
                  +201065532784
                </a>
                <i className="fa-solid fa-phone fa-3 px-2 py-1 xIcon"></i>
                <a className="link" href="tel:+201118832733">
                  +201118832733
                </a>
              </div>
            </div>

            <div className="mb-2 all-div">
              <div className="d-flex align-items-centerX">
                <i className="fa-solid fa-location-dot fa-3 px-2 py-1"></i>
                <p className="mb-0">{t("footer.KSA")}</p>
              </div>
              <div className="d-flex align-items-centerX wrap">
                <i className="fa-solid fa-phone fa-3 px-2 py-1"></i>
                <a className="link" href="tel:+966561685188">
                  +966561685188
                </a>
              </div>
            </div>

            <div className="mb-3 all-div">
              <div className="d-flex align-items-centerX">
                <i className="fa-solid fa-location-dot fa-3 px-2 py-1"></i>
                <p className="mb-0">{t("footer.UAE")}</p>
              </div>
              <div className="d-flex align-items-centerX wrap">
                <i className="fa-solid fa-phone fa-3 px-2 py-1"></i>
                <a className="link" href="tel:+971542374365">
                  +971542374365
                </a>
              </div>
            </div>

            <div className="d-flex mb-0 list-unstyled gap-4 icons">
              <Link
                aria-label="link"
                className="link"
                target="_blank"
                to={`${LoaderContextVarible?.facebookFooter}`}
              >
                <i className="fa-brands fa-facebook"></i>
              </Link>
              <Link
                aria-label="link"
                className="link"
                target="_blank"
                to={`${LoaderContextVarible?.youtubeFooter}`}
              >
                <i className="fa-brands fa-youtube"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;



