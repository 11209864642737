import React from "react";
import style from "./MainBtn.module.css"
export default function MainBtn(props) {
  return (
    <>
      <button className={style.MainBtn} onClick={props.functions}>
        {props.text}
      </button>
    </>
  );
}
