import React, { useEffect } from "react";
import MainBtn from "../MainBtn/MainBtn";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import useNotify from "../../hooks/useNotify";

export default function ContactSecHome() {
  let { ToastContainer, notify } = useNotify();
  const [t, i18n] = useTranslation("global");
  function sendDataToapi(dataX) {
    fetch("https://admin.innova.com.eg/api/send/user/message", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-token":
          "d5eab596caa0128af68cd5d2e56219c2e3945fb11c5f3fb3cc4245576bbd5c387aab3f1f3fd9a89ecd96cc35d6a9574e31b0c669201df343d299eae97b64f618",
      },
      body: JSON.stringify(dataX),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        formik.handleReset();
        notify("The message was sent successfully");
      });
  }

  let myValidation = Yup.object({
    name: Yup.string()
      .required(t("ContactSecHome.error.nameRequired"))
      .min(3, t("ContactSecHome.error.nameMin"))
      .max(15, t("ContactSecHome.error.nameMax")),
    email: Yup.string()
      .required(t("ContactSecHome.error.emailRequired"))
      .email(t("ContactSecHome.error.emailInvalid")),
    phone: Yup.string().required(t("ContactSecHome.error.phoneRequired")),
    subject: Yup.string()
      .required(t("ContactSecHome.error.subjectRequired"))
      .min(10, t("ContactSecHome.error.subjectMin"))
      .max(50, t("ContactSecHome.error.subjectMax")),
    message: Yup.string()
      .required(t("ContactSecHome.error.messageRequired"))
      .min(50, t("ContactSecHome.error.messageMin")),
  });

  let formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
    },
    validationSchema: myValidation,
    onSubmit: sendDataToapi,
  });

  useEffect(() => {
    formik.handleReset();
  }, [i18n.language]);

  return (
    <>
      <ToastContainer position="bottom-left" theme="dark" />
      <section id="ContactSecHome">
        <div className={`container`}>
          <div className="position-relative main-banner">
            <h2 className="main-banner-h2">
              {t("ContactSecHome.contact.title")}
            </h2>
            <p className="main-banner-p">
              {t("ContactSecHome.contact.subtitle")}
            </p>
          </div>
          <form
            id="form"
            className={`needs-validation form`}
            onSubmit={formik.handleSubmit}
          >
            <div className={`row `}>
              <div className={`form-outline col-12 col-md-6`}>
                <input
                  type="text"
                  className="form-control form-control-lg "
                  placeholder={t("ContactSecHome.contact.input.name")}
                  id="name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.name && formik.touched.name ? (
                  <div className="alert">{formik.errors.name}</div>
                ) : null}
              </div>
              <div className={`form-outline col-12 col-md-6`}>
                <input
                  type="email"
                  className="form-control form-control-lg"
                  placeholder={t("ContactSecHome.contact.input.email")}
                  id="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.email && formik.touched.email ? (
                  <div className="alert">{formik.errors.email}</div>
                ) : null}
              </div>

              <div className="form-outline">
                <input
                  type="number"
                  className="form-control form-control-lg"
                  placeholder={t("ContactSecHome.contact.input.phone")}
                  id="phone"
                  name="phone"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.phone && formik.touched.phone ? (
                  <div className="alert">{formik.errors.phone}</div>
                ) : null}
              </div>

              <div className="form-outline">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder={t("ContactSecHome.contact.input.subject")}
                  id="subject"
                  name="subject"
                  value={formik.values.subject}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.subject && formik.touched.subject ? (
                  <div className="alert">{formik.errors.subject}</div>
                ) : null}
              </div>
              <div className="form-outline">
                <textarea
                  type="text"
                  className={`form-control form-control-lg textarea`}
                  placeholder={t("ContactSecHome.contact.input.message")}
                  id="message"
                  name="message"
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.message && formik.touched.message ? (
                  <div className="alert">{formik.errors.message}</div>
                ) : null}
              </div>
              <div className={`load my-3`}>
                <MainBtn
                  functions={formik.handleSubmit}
                  text={t("ContactSecHome.contact.btn")}
                />
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}
