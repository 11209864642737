import React from "react";
import { useTranslation } from "react-i18next";
import aboutImg from "../../imgs/About us page-bro.svg";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Helmet } from "react-helmet";

export default function About() {
  const { t } = useTranslation("global");

  return (
    <>
      <Helmet>
        <title>INNOVA | About Us</title>
      </Helmet>
      <section id="About" className="mt-5">
        <div className="container mt-3">
          <div className="position-relative main-banner">
            <h2 className="main-banner-h2">{t("about.title")}</h2>
            <p className="main-banner-p">{t("about.subtitle")}</p>
          </div>
          <div className="row rowX">
            <div className="col-md-6 left-side">
              <h2 className="main-color">{t("about.innova")}</h2>
              <p className="text-justify">{t("about.description1")}</p>
              <ul>
                <li>{t("about.activity1")}</li>
                <li>{t("about.activity2")}</li>
                <li>{t("about.activity3")}</li>
                <li>{t("about.activity4")}</li>
                <li>{t("about.activity5")}</li>
              </ul>
              <p className="text-justify">{t("about.description2")}</p>
              <p className="text-justify">{t("about.description3")}</p>
              <p className="text-justify">{t("about.description4")}</p>
              <h3 className="main-color">{t("about.sectors")}</h3>
              <ul>
                <li>{t("about.sector1")}</li>
                <li>{t("about.sector2")}</li>
                <li>{t("about.sector3")}</li>
                <li>{t("about.sector4")}</li>
                <li>{t("about.sector5")}</li>
                <li>{t("about.sector6")}</li>
                <li>{t("about.sector7")}</li>
                <li>{t("about.sector8")}</li>
              </ul>
              <h3 className="main-color">{t("about.accreditations")}</h3>
              <ul>
                <li>{t("about.accreditation1")}</li>
                <li>{t("about.accreditation2")}</li>
                <li>{t("about.accreditation3")}</li>
              </ul>
            </div>
            <div className="col-md-6 mb-3 mb-md-0 right-side">
              <img className="img-fluid" src={aboutImg} alt="" />
              <div className="mt-4">
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      <span className="fw-bold">{t("about.vision")}</span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{t("about.visionDescription")}</Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography>
                      <span className="fw-bold">{t("about.strategy")}</span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{t("about.strategyDescription")}</Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                  >
                    <Typography>
                      <span className="fw-bold">{t("about.coreValues")}</span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{t("about.coreValuesDescription")}</Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
