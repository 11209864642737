import React, { useState, useEffect, useContext } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Loader from "../Loader/Loader";
import { Link } from "react-router-dom";
import MainBtn from "../MainBtn/MainBtn";
import { useTranslation } from "react-i18next";
import { LoaderContext } from "../../context/LoaderContext";
// Atropos 3d
import Atropos from "atropos/react";
import { Helmet } from "react-helmet";

export default function ServicesSecHome() {
  const LoaderContextVarible = useContext(LoaderContext);

  const [t, i18n] = useTranslation("global");
  const [cards, setCards] = useState([]);
  const [currentCards, setCurrentCards] = useState(6);
  const [hasMore, setHasMore] = useState(true);

  const [data, setData] = useState([]);
  useEffect(() => {
    LoaderContextVarible.setLoaderProjects(true);
    fetch("https://admin.innova.com.eg/api/projects", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-api-token":
          "d5eab596caa0128af68cd5d2e56219c2e3945fb11c5f3fb3cc4245576bbd5c387aab3f1f3fd9a89ecd96cc35d6a9574e31b0c669201df343d299eae97b64f618",
        "Accept-Language": i18n.language,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data.data);
        setTimeout(() => {
          LoaderContextVarible.setLoaderProjects(false);
        }, 1000);
      })
      .catch((error) => {
        setTimeout(() => {
          LoaderContextVarible.setLoaderProjects(false);
        }, 1000);
      });
  }, [i18n.language]);

  useEffect(() => {
    const cardsToShow = data?.slice(0, currentCards);
    setCards(cardsToShow);
    if (data?.length) {
      if (currentCards >= data?.length) {
        setHasMore(false);
      }
    }
  }, [currentCards, data]);

  const loadMore = () => {
    setCurrentCards(currentCards + 3);
  };

  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    const filteredCards = data.filter((card) =>
      card.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setCards(filteredCards);
  }, [searchTerm]);

  return (
    <>
      <Helmet>
        <title>INNOVA | Projects</title>
      </Helmet>
      {LoaderContextVarible.loaderProjects ? (
        <Loader />
      ) : (
        <section id="Projects" className="mt-5">
          <div className="container mt-3">
            <div className="position-relative main-banner">
              <h2 className="main-banner-h2">
                {t("ProjectsSecHome.projects.title")}
              </h2>
              <p className="main-banner-p">
                {t("ProjectsSecHome.projects.subtitle")}
              </p>
            </div>

            <div className="form-outline d-flex justify-content-center">
              <input
                type="text"
                placeholder={t("gen.search")}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="form-control form-control-lg mb-3"
              />
            </div>
            <TransitionGroup className="card-container justify-content-center row g-3">
              {cards.map((el, i) => (
                <CSSTransition key={i} timeout={1000} classNames="fade">
                  <div className="col-xl-4 col-sm-6">
                    <Atropos
                      className="my-atropos"
                      rotateTouch={false}
                      shadowScale={0.9}
                    >
                      <div className="card">
                        <div className="card-body">
                          <div className="head-of-body">
                            <img
                              className="w-100"
                              src={el.image}
                              loading="lazy"
                              alt={`img ${i}`}
                            />
                          </div>
                          <div className="body-of-body">
                            <h2 className="card-title h5 mb-0">{el.title}</h2>
                            <div className="d-flex justify-content-between wrap">
                              <p className="m-0">{el.area}</p>
                              <p className="m-0">{el.date}</p>
                            </div>
                            <Link
                              className="show-more"
                              to={`/projectsDetails/${el.id}`}
                            >
                              {t("gen.showmore")}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </Atropos>
                  </div>
                </CSSTransition>
              ))}
            </TransitionGroup>
            {hasMore && (
              <div className="text-center mt-4">
                <MainBtn
                  text="Load More"
                  functions={() => {
                    loadMore();
                  }}
                />
              </div>
            )}
          </div>
        </section>
      )}
    </>
  );
}
