import React, { useContext, useState } from "react";
import AboutSecHomeImg from "../../imgs/aboutSecHome.jpg";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import { useTranslation } from "react-i18next";
import MainBtn from "../MainBtn/MainBtn";
import ExperienceReferenceList from "../../imgs/Experience Reference List.xlsx";
import { LoaderContext } from "../../context/LoaderContext";

export default function AboutSecHome() {
  const LoaderContextVarible = useContext(LoaderContext);
  const [counterOn, setCounterOn] = useState(false);
  const { t } = useTranslation("global");
  return (
    <section id="AboutSecHome">
      <div className="container">
        <div className="position-relative main-banner">
          <h2 className="main-banner-h2">{t("AboutSecHome.about.title")}</h2>
          <p className="main-banner-p">{t("AboutSecHome.about.subtitle")}</p>
        </div>
        <div className="row rowX">
          <div className="col-lg-8 left-side flex-center">
            <h2 className="w-md-70 m-0">
              {t("AboutSecHome.about.mainTitle1")}{" "}
              <span className="main-color">
                {t("AboutSecHome.about.mainTitle2")}
              </span>{" "}
              {t("AboutSecHome.about.mainTitle3")}
            </h2>

            <p className="m-0 mt-3">{t("AboutSecHome.about.description")}</p>

            <div className="my-3">
              <MainBtn
                text={
                  <a className="MainBtn" href={ExperienceReferenceList}>
                    {t("AboutSecHome.about.btn")}
                  </a>
                }
              />
            </div>

            <ScrollTrigger
              onEnter={() => {
                setCounterOn(true);
              }}
              onExit={() => {
                setCounterOn(false);
              }}
            >
              <div className="row g-3">
                <div className="col-6 col-sm-3">
                  <div className="card">
                    {counterOn && (
                      <p className="h1 main-counter">
                        <span className="main-color">+</span>
                        <CountUp
                          start={0}
                          end={LoaderContextVarible?.projectsAbout}
                          duration={2}
                        />{" "}
                      </p>
                    )}
                    <p className="h4">
                      {t("AboutSecHome.about.counter.projects")}
                    </p>
                  </div>
                </div>
                <div className="col-6 col-sm-3">
                  <div className="card">
                    {counterOn && (
                      <p className="h1 main-counter">
                        <span className="main-color">+</span>
                        <CountUp
                          start={0}
                          end={LoaderContextVarible?.clientAbout}
                          duration={2}
                        />{" "}
                      </p>
                    )}
                    <p className="h4">
                      {t("AboutSecHome.about.counter.clients")}
                    </p>
                  </div>
                </div>
                <div className="col-6 col-sm-3">
                  <div className="card">
                    {counterOn && (
                      <p className="h1 main-counter">
                        <span className="main-color">+</span>
                        <CountUp
                          start={0}
                          end={LoaderContextVarible?.membersAbout}
                          duration={2}
                        />{" "}
                      </p>
                    )}
                    <p className="h4">
                      {t("AboutSecHome.about.counter.members")}
                    </p>
                  </div>
                </div>
                <div className="col-6 col-sm-3">
                  <div className="card">
                    {counterOn && (
                      <p className="h1 main-counter">
                        <span className="main-color">+</span>
                        <CountUp
                          start={0}
                          end={LoaderContextVarible?.branchAbout}
                          duration={2}
                        />{" "}
                      </p>
                    )}
                    <p className="h4">
                      {t("AboutSecHome.about.counter.branches")}
                    </p>
                  </div>
                </div>
              </div>
            </ScrollTrigger>
          </div>
          <div className="col-lg-4 right-side flex-center">
            <img className="img-fluid" src={AboutSecHomeImg} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
}
