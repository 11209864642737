import { createContext, useEffect, useState } from "react";
export const LoaderContext = createContext();
export function LoaderContextProvider(props) {
  const [loaderMain, setLoaderMain] = useState(false);
  const [loaderSlides, setLoaderSlides] = useState(true);
  const [loaderNews, setLoaderNews] = useState(true);
  const [loaderNewsDetails, setLoaderNewsDetails] = useState(true);
  const [loaderServices, setLoaderServices] = useState(true);
  const [loaderProjects, setLoaderProjects] = useState(true);
  const [loaderProjectsDetails, setLoaderProjectsDetails] = useState(true);
  const [loaderCertificates, setLoaderCertificates] = useState(true);
  const [loaderCareer, setLoaderCareer] = useState(false);
  const [loaderCareerDetails, setLoaderCareerDetails] = useState(false);

  const [emailFooter, setEmailFooter] = useState("");
  const [facebookFooter, setFacebookFooter] = useState("");
  const [youtubeFooter, setYoutubeFooter] = useState("");

  const [branchAbout, setBranchAbout] = useState("");
  const [clientAbout, setClientAbout] = useState("");
  const [projectsAbout, setProjectsAbout] = useState("");
  const [membersAbout, setMembersAbout] = useState("");

  // get data for meta tags
  useEffect(() => {
    fetch("https://admin.innova.com.eg/api/settings", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-api-token":
          "d5eab596caa0128af68cd5d2e56219c2e3945fb11c5f3fb3cc4245576bbd5c387aab3f1f3fd9a89ecd96cc35d6a9574e31b0c669201df343d299eae97b64f618",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setEmailFooter(data.data[2].email);
        setFacebookFooter(data.data[3].facebook);
        setYoutubeFooter(data.data[4].youtube);

        setBranchAbout(data.data[5].branch);
        setClientAbout(data.data[6].client);
        setProjectsAbout(data.data[7].projects);
        setMembersAbout(data.data[8].members);

        const metaTagDescription = document.querySelector(
          'meta[name="description"]'
        );
        metaTagDescription.setAttribute(
          "content",
          data?.data[0]?.metaDescription ? data?.data[0]?.metaDescription : ""
        );

        const metaTagKeywords = document.querySelector('meta[name="keywords"]');
        metaTagKeywords.setAttribute(
          "content",
          data?.data[1]?.metaKeywords ? data?.data[1]?.metaKeywords : ""
        );
      });
  }, []);

  return (
    <LoaderContext.Provider
      value={{
        loaderMain,
        setLoaderMain,
        loaderSlides,
        setLoaderSlides,
        loaderNews,
        setLoaderNews,
        loaderNewsDetails,
        setLoaderNewsDetails,
        loaderServices,
        setLoaderServices,
        loaderProjects,
        setLoaderProjects,
        loaderProjectsDetails,
        setLoaderProjectsDetails,
        loaderCertificates,
        setLoaderCertificates,
        loaderCareer,
        setLoaderCareer,
        loaderCareerDetails,
        setLoaderCareerDetails,

        emailFooter,
        facebookFooter,
        youtubeFooter,

        branchAbout,
        clientAbout,
        projectsAbout,
        membersAbout,
      }}
    >
      {props.children}
    </LoaderContext.Provider>
  );
}
