import React, { useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
// import required modules
import { FreeMode, Autoplay } from "swiper/modules";

import { useTranslation } from "react-i18next";

export default function PartnersSecHome() {
  const [t, i18n] = useTranslation("global");
  const [data, setData] = useState([]);
  useEffect(() => {
    fetch("https://admin.innova.com.eg/api/clients", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-api-token":
          "d5eab596caa0128af68cd5d2e56219c2e3945fb11c5f3fb3cc4245576bbd5c387aab3f1f3fd9a89ecd96cc35d6a9574e31b0c669201df343d299eae97b64f618",
          "Accept-Language": i18n.language,
        },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((res) => {
        setData(res.data);
      });
  }, [i18n.language]);

  return (
    <section id="PartnersSecHome">
      <div className="container">
        <div className="position-relative main-banner">
          <h2 className="main-banner-h2">
            {t("PartnersSecHome.partners.title")}
          </h2>
          <p className="main-banner-p">
            {t("PartnersSecHome.partners.subtitle")}
          </p>
        </div>
        <Swiper
          dir="ltr"
          className="mySwiper"
          spaceBetween={60}
          grabCursor={true}
          autoplay={{
            delay: 1000,
            disableOnInteraction: false,
          }}
          freeMode={true}
          modules={[FreeMode, Autoplay]}
          loop={true}
          breakpoints={{
            280: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            700: {
              slidesPerView: 5,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 7,
              spaceBetween: 30,
            },
          }}
        >
          {data?.map((el, i) => (
            <SwiperSlide key={i}>
              <div className="partnerDiv">
                <img className="partnerImg" alt=".." src={el?.image} />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
}
