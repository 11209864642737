import React, { useEffect, useState } from "react";
import "./NavBar.css";
import logoImg from "../../imgs/logo.png";
import MainBtn from "../MainBtn/MainBtn";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function TemplateDemo() {
  const [isFixed1, setIsFixed1] = useState(false);
  const [isFixed2, setIsFixed2] = useState(false);

  const [t, i18n] = useTranslation("global");
  const handelChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("language", lang); // حفظ اللغة في localStorage عند التغيير
  };
  // useEffect(() => {
  //   const storedLanguage = localStorage.getItem("language");
  //   if (storedLanguage === "en") {
  //     handelChangeLanguage("en");
  //     document.body.classList.remove("ar");
  //   } else {
  //     handelChangeLanguage("ar");
  //     document.body.classList.add("ar");
  //   }
  // }, [i18n]);
  useEffect(() => {
    const dir = i18n.dir(i18n.language);
    document.documentElement.dir = dir;
    document.documentElement.lang = i18n.language;
    if (i18n.language === "ar") {
      document.body.classList.add("ar");
    } else {
      document.body.classList.remove("ar");
    }
  }, [i18n.language]);

  useEffect(() => {
    function handleScroll() {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 0) {
        setIsFixed1(true);
      } else {
        setIsFixed1(false);
      }
      if (scrollPosition > 300) {
        setIsFixed2(true);
      } else {
        setIsFixed2(false);
      }
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const pathname = window.location.pathname;
  useEffect(() => {
    // تحديد السيكشن المحدد بناءً على الهاش في رابط الصفحة
    const hash = window.location.hash;
    if (hash) {
      const sectionId = hash.slice(1); // إزالة علامة الهاش #
      const sectionElement = document.getElementById(sectionId);
      if (sectionElement) {
        sectionElement.scrollIntoView();
      }
    }
  }, []);

  const handleLinkClick = (sectionId) => {
    if (pathname === "/") {
      setTimeout(() => {
        const sectionElement = document.getElementById(sectionId);
        sectionElement.scrollIntoView(sectionId);
      }, 1);
    } else {
      setTimeout(() => {
        const sectionElement = document.getElementById(sectionId);
        sectionElement.scrollIntoView(sectionId);
      }, 1001);
    }
  };

  return (
    <>
      <header
        id="NavBar"
        className={!isFixed1 ? "NavBar" : "NavBar NavBarFixed"}
      >
        <nav className="navbar navbar-expand-lg lightMode">
          <div className="container">
            <NavLink
              aria-label="innova logo"
              className="navbar-brand"
              onClick={() => {
                setTimeout(() => {
                  window.scrollTo(0, 0);
                }, 1000);
              }}
              to="/"
            >
              <img src={logoImg} alt="innova logo" />
            </NavLink>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span></span>
              <span></span>
              <span></span>
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    to={"/"}
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    {t("navbar.home")}
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    to={"/about"}
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    {t("navbar.about")}
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    to={"/gallery"}
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    {t("navbar.gallery")}
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    to={"/projects"}
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    {t("navbar.projects")}
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    to={"/careers"}
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    {t("navbar.careers")}
                  </NavLink>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/#ServicesSecHome"
                    onClick={() => handleLinkClick("ServicesSecHome")}
                  >
                    {t("navbar.services")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/#PartnersSecHome"
                    onClick={() => handleLinkClick("PartnersSecHome")}
                  >
                    {t("navbar.partners")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/#CertificatesSecHome"
                    onClick={() => handleLinkClick("CertificatesSecHome")}
                  >
                    {t("navbar.certificates")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/#ContactSecHome"
                    onClick={() => handleLinkClick("ContactSecHome")}
                  >
                    {t("navbar.contact")}
                  </Link>
                </li>
                <li className="nav-item">
                  {i18n.language === "ar" ? (
                    <div className="langDiv">
                      <MainBtn
                        text="EN"
                        functions={() => {
                          handelChangeLanguage("en");
                        }}
                      />
                    </div>
                  ) : (
                    <div className="langDiv">
                      <MainBtn
                        text="ع"
                        functions={() => {
                          handelChangeLanguage("ar");
                        }}
                      />
                    </div>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      {isFixed2 && (
        <div
          onClick={() => {
            window.scrollTo(0, 0);
          }}
          className="toUp"
        >
          <i className="fa-solid fa-angles-up"></i>
        </div>
      )}
    </>
  );
}
