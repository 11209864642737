import React, { useContext, useEffect, useState } from "react";
import MainBtn from "../MainBtn/MainBtn";
import { LoaderContext } from "../../context/LoaderContext";
import { useTranslation } from "react-i18next";
import Loader from "../Loader/Loader";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer } from "react-toastify";
import useNotify from "../../hooks/useNotify";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function CareerDetails() {
  const LoaderContextVarible = useContext(LoaderContext);

  const [t, i18n] = useTranslation("global");

  let { ToastContainer, notify } = useNotify();
  const prams = useParams();

  function sendDataToapi(dataX) {
    fetch(`https://admin.innova.com.eg/api/apply/job/${prams.id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-token":
          "d5eab596caa0128af68cd5d2e56219c2e3945fb11c5f3fb3cc4245576bbd5c387aab3f1f3fd9a89ecd96cc35d6a9574e31b0c669201df343d299eae97b64f618",
      },
      body: JSON.stringify(dataX),
    })
      .then((response) => {
        response.json();
      })
      .then((data) => {
        // console.log(data);
        formik.handleReset();
        notify("Your Application was sent successfully");
      })
      .catch((error) => {
        notify("Error, Please Try Again Later");
      });
  }

  let myValidation = Yup.object({
    first_name: Yup.string()
      .required(t("CareerDetails.error.fristRequired"))
      .min(2, t("CareerDetails.error.fristMin"))
      .max(15, t("CareerDetails.error.fristMax")),
    last_name: Yup.string()
      .required(t("CareerDetails.error.lastRequired"))
      .min(2, t("CareerDetails.error.lastMin"))
      .max(15, t("CareerDetails.error.lastMax")),
    phone: Yup.string().required(t("CareerDetails.error.phoneRequired")),
    email: Yup.string()
      .required(t("CareerDetails.error.emailRequired"))
      .email(t("CareerDetails.error.emailInvalid")),
    cv: Yup.string().required(t("CareerDetails.error.cvRequired")),
  });

  let formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      cv: "",
    },
    validationSchema: myValidation,
    onSubmit: sendDataToapi,
  });

  useEffect(() => {
    formik.handleReset();
  }, [i18n.language]);

  const [data, setData] = useState(null);
  useEffect(() => {
    LoaderContextVarible.setLoaderCareerDetails(true);

    fetch(`https://admin.innova.com.eg/api/job/${prams.id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-api-token":
          "d5eab596caa0128af68cd5d2e56219c2e3945fb11c5f3fb3cc4245576bbd5c387aab3f1f3fd9a89ecd96cc35d6a9574e31b0c669201df343d299eae97b64f618",
        "Accept-Language": i18n.language,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data.data);
        setTimeout(() => {
          LoaderContextVarible.setLoaderCareerDetails(false);
        }, 1000);
      })
      .catch((error) => {
        setTimeout(() => {
          LoaderContextVarible.setLoaderCareerDetails(false);
        }, 1000);
      });
  }, [i18n.language]);

  return (
    <>
      <Helmet>
        <title>INNOVA | {data?.title || ""}</title>
      </Helmet>
      <ToastContainer position="bottom-left" theme="dark" />
      {LoaderContextVarible.loaderCareerDetails ? (
        <Loader />
      ) : (
        <section id="CareerDetails" className="mt-5">
          <div className="container mt-3">
            <div className="position-relative main-banner">
              <h2 className="main-banner-h2">{t("CareerDetails.title")}</h2>
              <p className="main-banner-p">{t("CareerDetails.subtitle")}</p>
            </div>

            <div className="row rowX">
              <div className="col-lg-6 left-side">
                <h2 className="main-color mb-3">
                  {t("CareerDetails.startHeader")}
                </h2>

                <form
                  id="form"
                  className={`needs-validation form`}
                  onSubmit={formik.handleSubmit}
                >
                  <div className={`row`}>
                    <div className={`form-outline col-12 col-md-6`}>
                      <input
                        type="text"
                        className="form-control form-control-lg "
                        placeholder={t("CareerDetails.first_name")}
                        id="first_name"
                        name="first_name"
                        value={formik.values.first_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors.first_name && formik.touched.first_name ? (
                        <div className="alert">{formik.errors.first_name}</div>
                      ) : null}
                    </div>
                    <div className={`form-outline col-12 col-md-6`}>
                      <input
                        type="text"
                        className="form-control form-control-lg "
                        placeholder={t("CareerDetails.last_name")}
                        id="last_name"
                        name="last_name"
                        value={formik.values.last_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors.last_name && formik.touched.last_name ? (
                        <div className="alert">{formik.errors.last_name}</div>
                      ) : null}
                    </div>

                    <div className="form-outline">
                      <input
                        type="number"
                        className="form-control form-control-lg"
                        placeholder={t("CareerDetails.phone")}
                        id="phone"
                        name="phone"
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors.phone && formik.touched.phone ? (
                        <div className="alert">{formik.errors.phone}</div>
                      ) : null}
                    </div>

                    <div className={`form-outline col-12 `}>
                      <input
                        type="email"
                        className="form-control form-control-lg"
                        placeholder={t("CareerDetails.email")}
                        id="email"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors.email && formik.touched.email ? (
                        <div className="alert">{formik.errors.email}</div>
                      ) : null}
                    </div>

                    <div className={`form-outline col-12 `}>
                      <input
                        type="file"
                        className="form-control form-control-lg"
                        placeholder={t("CareerDetails.cv")}
                        id="cv"
                        name="cv"
                        value={formik.values.cv}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        title={t("CareerDetails.cv")}
                      />
                      {formik.errors.cv && formik.touched.cv ? (
                        <div className="alert">{formik.errors.cv}</div>
                      ) : null}
                    </div>

                    <div className={`load my-3`}>
                      <MainBtn
                        functions={formik.handleSubmit}
                        text={t("CareerDetails.btn")}
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-lg-6 right-side mb-3">
                <h2 className="main-color mb-3">
                  {t("CareerDetails.endHeader")}
                </h2>
                <div className="card p-2">
                  <div className="allCard">
                    <div className="allCardX main-color">
                      <i className="fa-solid fa-briefcase"></i>
                      <h5 className="m-0">{t("CareerDetails.jobTitle")} : </h5>
                    </div>
                    <p>{data?.title}</p>
                  </div>
                  <div className="allCard">
                    <div className="allCardX main-color">
                      <i className="fa-solid fa-barcode"></i>
                      <h5 className="m-0">{t("CareerDetails.jobCode")} : </h5>
                    </div>
                    <p>{data?.code}</p>
                  </div>
                  <div className="allCard">
                    <div className="allCardX main-color">
                      <i className="fa-solid fa-hourglass-start"></i>
                      <h5 className="m-0">{t("CareerDetails.startDate")} : </h5>
                    </div>
                    <p>{data?.start_date}</p>
                  </div>
                  <div className="allCard">
                    <div className="allCardX main-color">
                      <i className="fa-solid fa-hourglass-end"></i>
                      <h5 className="m-0">{t("CareerDetails.endtDate")} : </h5>
                    </div>
                    <p>{data?.end_date}</p>
                  </div>
                  <div className="allCard allCardX">
                    <div className="allCardX main-color">
                      <i className="fa-solid fa-file-lines"></i>
                      <h5 className="m-0">{t("CareerDetails.aboutJob")} : </h5>
                    </div>
                    {React.createElement("div", {
                      className: "text-justify",
                      dangerouslySetInnerHTML: { __html: data?.summary },
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}
