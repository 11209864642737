import React, { useState, useEffect, useContext } from "react";
import { Modal, Button } from "react-bootstrap";

// Atropos 3d
import Atropos from "atropos/react";
import { useTranslation } from "react-i18next";
import { LoaderContext } from "../../context/LoaderContext";

export default function ServicesSecHome() {
  const LoaderContextVarible = useContext(LoaderContext);

  const [t, i18n] = useTranslation("global");

  const [data, setData] = useState([]);
  const [selectedService, setSelectedService] = useState(null); // الخدمة المحددة
  const [showModal, setShowModal] = useState(false); // حالة ظهور النموذج

  useEffect(() => {
    fetch("https://admin.innova.com.eg/api/services", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-api-token":
          "d5eab596caa0128af68cd5d2e56219c2e3945fb11c5f3fb3cc4245576bbd5c387aab3f1f3fd9a89ecd96cc35d6a9574e31b0c669201df343d299eae97b64f618",
        "Accept-Language": i18n.language,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data.data);
        LoaderContextVarible.setLoaderServices(false);
      })
      .catch((error) => {
        LoaderContextVarible.setLoaderServices(false);
      });
  }, [i18n.language]);

  // يتم استدعاءها عند النقر على الخدمة
  const handleServiceClick = (service) => {
    setSelectedService(service);
    setShowModal(true);
  };
  // يتم استدعاؤها عند إغلاق النموذج
  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <section id="ServicesSecHome">
        <div className="container">
          <div className="position-relative main-banner">
            <h2 className="main-banner-h2">
              {t("ServicesSecHome.services.title")}
            </h2>
            <p className="main-banner-p">
              {t("ServicesSecHome.services.subtitle")}
            </p>
          </div>
          <div className="card-container row g-3 ">
            {data.map((el, i) => (
              <div key={i} className="col-xl-4 col-sm-6">
                <Atropos
                  className="my-atropos cursor-pointer"
                  rotateTouch={false}
                  shadowScale={0.9}
                  onClick={() => handleServiceClick(el)}
                >
                  <div className="card">
                    <div className="card-body">
                      <div className="head-of-body">
                        <img
                          className="w-100 h-100"
                          src={el.image}
                          alt={el.name}
                        />
                      </div>
                      <div className="body-of-body">
                        <h2 className="card-title h4">{el.name}</h2>
                      </div>
                    </div>
                  </div>
                </Atropos>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* modal */}
      <Modal size="lg" centered show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <img
            className="w-100"
            src={selectedService?.image}
            alt={selectedService?.name}
          />
          <h5 className="mt-3 main-color">{selectedService?.name}</h5>

          {React.createElement("div", {
            className: "text-justify",
            dangerouslySetInnerHTML: { __html: selectedService?.description },
          })}
        </Modal.Body>
      </Modal>
    </>
  );
}
