import React, { useContext, useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import { LoaderContext } from "../../context/LoaderContext";
import { useTranslation } from "react-i18next";
export default function MainSecHome() {
  const LoaderContextVarible = useContext(LoaderContext);
  const [t, i18n] = useTranslation("global");

  // get slides
  const [data, setData] = useState([]);
  useEffect(() => {
    LoaderContextVarible.setLoaderSlides(true);
    fetch("https://admin.innova.com.eg/api/sliders", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-api-token":
          "d5eab596caa0128af68cd5d2e56219c2e3945fb11c5f3fb3cc4245576bbd5c387aab3f1f3fd9a89ecd96cc35d6a9574e31b0c669201df343d299eae97b64f618",
        "Accept-Language": i18n.language,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data.data);
        setTimeout(() => {
          LoaderContextVarible.setLoaderSlides(false);
        }, 1000);
      })
      .catch((error) => {
        setTimeout(() => {
          LoaderContextVarible.setLoaderSlides(false);
        }, 1000);
      });
  }, [i18n.language]);

  return (
    <>
      <section id="MainSecHome" className="text-light">
        <Carousel fade interval={5000} indicators={false} pause={false}>
          {data.map((el, i) => (
            <Carousel.Item key={i}>
              <img src={el?.image} alt={el?.description} />
              <Carousel.Caption className="text text-center">
                <h2 className="fw-bold lh-1 mb-2">{el?.description}</h2>
                <p className="h3">{el?.alt}</p>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
        <a
          href="#AboutSecHome"
          className="scroll position-absolute bottom-0 translate-middle start-50 rounded-pill d-flex justify-content-center align-items-center"
        >
          <div className="scrollX bg-light rounded-circle position-absolute"></div>
        </a>
      </section>
    </>
  );
}
