import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { LoaderContext } from "../../context/LoaderContext";
import { Link } from "react-router-dom";

export default function ProjectsSecHome() {
  const LoaderContextVarible = useContext(LoaderContext);

  const [t, i18n] = useTranslation("global");

  const [data, setData] = useState([]);
  useEffect(() => {
    LoaderContextVarible.setLoaderProjects(true);
    fetch("https://admin.innova.com.eg/api/projects", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-api-token":
          "d5eab596caa0128af68cd5d2e56219c2e3945fb11c5f3fb3cc4245576bbd5c387aab3f1f3fd9a89ecd96cc35d6a9574e31b0c669201df343d299eae97b64f618",
          "Accept-Language": i18n.language,
        },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data.data.slice(0, 5));
        LoaderContextVarible.setLoaderProjects(false);
      })
      .catch((error) => {
        LoaderContextVarible.setLoaderProjects(false);
      });
  }, [i18n.language]);
  return (
    <section id="ProjectsSecHome">
      <div className="container" dir="ltr">
        <div className="position-relative main-banner">
          <h2 className="main-banner-h2">
            {t("ProjectsSecHome.projects.title")}
          </h2>
          <p className="main-banner-p">
            {t("ProjectsSecHome.projects.subtitle")}
          </p>
        </div>
        <VerticalTimeline animate={true}>
          {data.map((el, i) => (
            <VerticalTimelineElement
              key={i}
              icon={<i className="fa-solid fa-diagram-project"></i>}
              className="vertical-timeline-element--work"
              date={el.date}
            >
              <h3 className="vertical-timeline-element-title">{el.title}</h3>
              <p className="my-1">{el.area}</p>
              <h6 className="my-1">
                <Link className="show-more" to={`/projectsDetails/${el.id}`}>
                  {t("gen.showmore")}
                </Link>
              </h6>
            </VerticalTimelineElement>
          ))}
          <VerticalTimelineElement
            icon={<i className="fa-solid fa-star"></i>}
            iconStyle={{ background: "#a2ff86", color: "#fff" }}
          />
        </VerticalTimeline>
      </div>
    </section>
  );
}
