import React, { useContext, useEffect, useState } from "react";
import { LoaderContext } from "../../context/LoaderContext";
import { useTranslation } from "react-i18next";
import Loader from "../Loader/Loader";
import Atropos from "atropos/react";

export default function Gallery() {
  const LoaderContextVarible = useContext(LoaderContext);
  const [t, i18n] = useTranslation("global");

  const [dataX, setDataX] = useState([]);
  useEffect(() => {
    LoaderContextVarible.setLoaderProjects(true);
    fetch("https://admin.innova.com.eg/api/projects", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-api-token":
          "d5eab596caa0128af68cd5d2e56219c2e3945fb11c5f3fb3cc4245576bbd5c387aab3f1f3fd9a89ecd96cc35d6a9574e31b0c669201df343d299eae97b64f618",
        "Accept-Language": i18n.language,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setDataX(data.data);
        setTimeout(() => {
          LoaderContextVarible.setLoaderProjects(false);
        }, 1000);
      })
      .catch((error) => {
        setTimeout(() => {
          LoaderContextVarible.setLoaderProjects(false);
        }, 1000);
      });
  }, [i18n.language]);
  return (
    <>
      {LoaderContextVarible.loaderProjects ? (
        <Loader />
      ) : (
        <section id="Gallery" className="mt-5">
          <div className="container mt-3">
            <div className="position-relative main-banner">
              <h2 className="main-banner-h2">{t("Gallery.title")}</h2>
              <p className="main-banner-p">{t("Gallery.subtitle")}</p>
            </div>
            {dataX?.map((el, i) => (
              <div key={i} className="main">
                {el.gallery.length > 0 && (
                  <h2 className="title main-color">{el.title}</h2>
                )}
                <div className="imgs">
                  {el.gallery.map((el, i) => (
                    <div className="img" key={i}>
                      <Atropos
                        className="my-atropos"
                        rotateTouch={false}
                        shadowScale={0.9}
                      >
                        <img
                          className="img-fluid"
                          src={el.image}
                          alt={el.title}
                        />
                      </Atropos>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </section>
      )}
    </>
  );
}
