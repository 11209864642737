import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import careersImg from "../../imgs/Editorial commision-bro.svg";
import MainBtn from "../MainBtn/MainBtn";
import { LoaderContext } from "../../context/LoaderContext";
import Loader from "../Loader/Loader";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
export default function Career() {
  const LoaderContextVarible = useContext(LoaderContext);

  const [t, i18n] = useTranslation("global");

  const [data, setData] = useState([]);
  useEffect(() => {
    LoaderContextVarible.setLoaderCareer(true);

    fetch("https://admin.innova.com.eg/api/career", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-api-token":
          "d5eab596caa0128af68cd5d2e56219c2e3945fb11c5f3fb3cc4245576bbd5c387aab3f1f3fd9a89ecd96cc35d6a9574e31b0c669201df343d299eae97b64f618",
        "Accept-Language": i18n.language,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data.data);
        setTimeout(() => {
          LoaderContextVarible.setLoaderCareer(false);
        }, 1000);
      })
      .catch((error) => {
        setTimeout(() => {
          LoaderContextVarible.setLoaderCareer(false);
        }, 1000);
      });
  }, [i18n.language]);

  return (
    <>
      <Helmet>
        <title>INNOVA | Careers</title>
      </Helmet>
      {LoaderContextVarible.loaderCareer ? (
        <Loader />
      ) : (
        <section id="Careers" className="mt-5">
          <div className="container mt-3">
            <div className="position-relative main-banner">
              <h2 className="main-banner-h2">{t("career.title")}</h2>
              <p className="main-banner-p">{t("career.subtitle")}</p>
            </div>
            <div className="row rowX">
              <div className="col-lg-8 left-side flex-center">
                <h2 className="main-color">{t("career.joinUs")}</h2>
                <p className="mb-2">{t("career.mainHeader")}</p>
                <p className="mb-2 text-justify">{t("career.Header1")}</p>
              </div>
              <div className="col-lg-4 right-side flex-center">
                <img src={careersImg} alt=".." className="img-fluid" />
              </div>
            </div>

            {data.length > 0 && (
              <h2 className="main-color mb-3">
                {t("career.jobOpportunities")}
              </h2>
            )}
            {data?.map((el, i) => (
              <div className="card mb-3" key={i}>
                <div className="row">
                  <div className="col-md-6 allCard">
                    <i className="fa-solid fa-briefcase main-color"></i>
                    <h5 className="m-0">{el.title}</h5>
                  </div>
                  <div className="col-md-6 allCard">
                    <i className="fa-solid fa-barcode main-color"></i>
                    <h5 className="m-0">{el.code}</h5>
                  </div>
                  <div className="col-md-6 allCard">
                    <i className="fa-solid fa-hourglass-start main-color"></i>
                    <h5 className="m-0">{el.start_date}</h5>
                  </div>
                  <div className="col-md-6 allCard">
                    <i className="fa-solid fa-hourglass-end main-color"></i>
                    <h5 className="m-0">{el.end_date}</h5>
                  </div>
                  <div className="col-12 allCard allCardX">
                    <i className="fa-solid fa-file-lines main-color"></i>
                    {React.createElement("div", {
                      className: "text-justify",
                      dangerouslySetInnerHTML: { __html: el.summary },
                    })}
                  </div>

                  <div>
                    <Link to={`/careersDetails/${el.id}`}>
                      <MainBtn text={t("career.btn")} />
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      )}
    </>
  );
}
